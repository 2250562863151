import React from 'react'
import { About, Footer, Header, Skills, Testimonials, Work, Home } from './container'
import { Navbar } from './components';
import './App.scss';
import './App.css'
import './index.css'; // Import the CSS file


const App = () => {
    return (
        <div className="app">
            <Navbar />
            <Header /> 
            <Work />
            <Skills />
            <Footer />
        </div>
    );
}

export default App;
