import React, { useState } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';


const Footer = () => {

    const [formData, setFormData] = useState({ name: '', email: '', message: ''});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const { name, email, message } = formData; /* destructing = pull the value out of the constant */

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        /* destructuramos formData y ponemos name = value */
        setFormData({ ...formData, [name]: value});
    }
    
    const handleSubmit = () => {
        setLoading(true);

        const contact = {
            _type: 'contact',
            name: name,
            email: email,
            message: message,
        }

        client.create(contact)
            .then(() => {
                setLoading(false);
                setIsFormSubmitted(true);
            })
    }

    return (
        <>
            <h2 className="head-text">Contact <span>me </span> </h2>

            <div className="app__footer-cards">
                <div className="app__footer-card">
                    <img src="email.png" alt="email" />
                    <a href="mailto:guillecanovas99@gmail.com" className="p-text">guillecanovas99@gmail.com</a>
                </div>

                <div className="app__footer-card">
                    <img src="mobile.png" alt="mobile" />
                    <a href="tel: +34 606 391 299" className="p-text">+34 606 391 299</a>
                </div>
            </div>

            {/* si no se ha submiteado ningun mensaje mostramos los formularios sino le decimos que gracias jeje */}    
            {!isFormSubmitted ? 
                <div className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input className="p-text" type="text" placeholder="Your Name" name="name" value={name} onChange={handleChangeInput}/>
                    </div>
                    <div className="app__flex">
                        <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput}/>
                    </div>
                    <div>
                        <textarea
                            className="p-text"
                            placeholder="Your Message"
                            value={message}
                            name="message"
                            onChange={handleChangeInput}
                        />
                    </div>
                    <button type="button" className="p-text" onClick={handleSubmit}>{ loading ? 'Sending' : 'Send Message'}</button>
                </div>
                : <div>
                    <h3 className="head-text">Thank you for getting in touch</h3>
                </div>
            }
            
        </>
    )
}

export default AppWrap(
    MotionWrap(Footer,'app__footer'),
    'contact',
    'app__whitebg'
)

