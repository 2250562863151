export const projects = [
    {
        _id: "1",
        title: "Models for the detection of attacks in smart grids",
        description: "Software Engineering Bachelor's Thesis graded 9.5/10 with honors.",
        image: "tfg.jpg",
        github: "https://github.com/guillecanovas/tfg-guillermo-canovas",
        demo: "TAZ-TFG-2023-3558.pdf",
        category: "Data & AI",
    },
    {
        _id: "2",
        title: "Tuberculosis X-Ray Detection System",
        description: "Machine learning-based diagnostic tool to detect Tuberculosis.",
        image: "Tuberculosis-25.png",
        github: "https://github.com/guillecanovas/TuberculosisXRayDetection",
        demo: "https://github.com/guillecanovas/TuberculosisXRayDetection/blob/main/TuberculosisClassification.ipynb", //En un futuro link al medium
        category: "Data & AI",
    },
    {
        _id: "3",
        title: "Voice Classification",
        description: "Neural network-based classification model for voice classification.",
        image: "MD4JU.png",
        github: "https://github.com/guillecanovas/VoiceBinaryClassification",
        demo: "https://github.com/guillecanovas/VoiceBinaryClassification/blob/main/VoiceBinaryClassification.ipynb", //En un futuro link al medium
        category: "Data & AI",
    },
    {
        _id: "4",
        title: "Diabetes Detection System",
        description: "A diagnostic tool for detecting diabetes based on data analysis and machine learning",
        image: "diabetes.png",
        github: "https://github.com/guillecanovas/DiabetesPrediction",
        demo: "https://github.com/guillecanovas/DiabetesPrediction/blob/main/DiabetesPrediction.ipynb",
        category: "Data & AI",
    },
    {
        _id: "5",
        title: "Flytrax",
        description: "Web app that provides real-time flight information for airports worldwide and encourages community engagement through dedicated airport forums.",
        image: "flytrax.png",
        github: "https://github.com/UNIZAR-STW-2023",
        demo: "https://www.flytrax.es/",
        category: "Web",
    },
    {
        _id: "6",
        title: "Gym Tracker App",
        description: "An application that tracks gym workouts, records lifting sessions, and visually charts the progress of each lift over time,  developed using React Native. (Unfinished Project)",
        image: "feature.png",
        github: "https://github.com/guillecanovas/gym-tracker-app",
        demo: "https://github.com/guillecanovas/gym-tracker-app/blob/main/README.md",
        category: "Mobile",
    },
    {
        _id: "7",
        title: "SportMate App",
        description: "Designed for connecting with people who share your sports interests, allowing you to arrange meet-ups for sport activities through a chat,  developed using React Native. (Unfinished Project)",
        image: "sportmate.png",
        github: "https://github.com/guillecanovas/sport-mate-app",
        demo: "https://github.com/guillecanovas/sport-mate-app/blob/main/README.md",
        category: "Mobile",
    },
    {
        _id: "8",
        title: "E-commerce App",
        description: "A React-based UI/UX-focused e-commerce web application with Stripe payment integration for seamless online clothing shopping.",
        image: "ecomm.png",
        github: "https://github.com/guillecanovas/ecommerce-app",
        demo: "https://ecommerce-app-one-gilt.vercel.app/",
        category: "Web",
    },
    {
        _id: "9",
        title: "Gym Tracker Landing Page",
        description: "React-based landing page, combining attractive design and functionality to highlight key app features, engage users, and encourage downloads.",
        image: "gtrack.png",
        github: "https://github.com/guillecanovas/gtrack-landing-page",
        demo: "https://gtrack-landing-page.vercel.app/",
        category: "Web",
    }
]


export const skills_obj = [
    {
        _id: "1",
        title: "React",
        image: "react.png"
    },
    {
        _id: "2",
        title: "Python",
        image: "python.png"
    },
    {
        _id: "3",
        title: "Jupyter",
        image: "jupyter.png"

    },
    {
        _id: "4",
        title: "Tensorflow",
        image: "tensorflow.png"
    },
    {
        _id: "5",
        title: "Git",
        image: "git.png"
    },
    {
        _id: "6",
        title: "Javascript",
        image: "javascript.png"
    },
    {
        _id: "7",
        title: "Typescript",
        image: "typescript.png"
    },
    {
        _id: "8",
        title: "CSS",
        image: "css.png"
    },
    {
        _id: "9",
        title: "Node JS",
        image: "nodejs.png"
    },
    {
        _id: "10",
        title: "MongDB",
        image: "mongo.png"
    },
    {
        _id: "11",
        title: "PostgreSQL",
        image: "postgre.png"
    },
    {
        _id: "12",
        title: "MySQL",
        image: "mysql.png"
    },
    {
        _id: "13",
        title: "Kubernetes",
        image: "kubernetes.png"
    },
    {
        _id: "14",
        title: "C++",
        image: "c++.png"
    },
    {
        _id: "15",
        title: "C",
        image: "c.png"
    },
    {
        _id: "16",
        title: "Java",
        image: "java.png"
    },
]


export const education = [
    {
        _id: "1",
        title: "Web App",
    },
    {
        _id: "2",
        title: "Mobile App",
    },
    {
        _id: "3",
        title: "Data & AI"
    }
]

