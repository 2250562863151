import React from 'react'
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import './Header.scss';
import Data from '../Home/Data'
import Social from '../Home/Social'

const scaleVariants = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

const Header = () => {
    return (
        <div className="app__header app__flex">
        
            <div className='home__container container grid'>
            <div className='home__content grid'>
                <Social />
                <div className='home__img'>

                </div>

                <Data/>
            </div>
        </div>
        </div>
    )
}

/* le pasamos el id home directamente por aqui */
export default AppWrap(Header, 'home');
